<template>
    <div>
        <div class="second-title">导入设置</div>
        <el-row>
            <el-col :span="6" class="second-label">导入过程中遇到错误</el-col>
            <el-col :span="18">
                <el-radio v-model="importForm.errType" label="1">继续执行</el-radio>
                <el-radio v-model="importForm.errType" label="2">终止导入</el-radio>
                <div class="second-content">
                    <p>继续执行：遇到错误数据不中断，正确数据导入完成后统一导出错误信息，错误信息不导入;</p>
                    <p>终止导入：数据必须全部无误才可导入成功，一旦遇到错误即终止，系统导出全部数据，标记错误数据，修改后重新导入</p>
                </div>
            </el-col>
        </el-row>
        <div style="text-align: center">
            <el-button type="primary" @click="save">下一步</el-button>
            <el-button type="info" @click="before">返回重新上传</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            importForm: {
                type: Object,
            },

        },
        methods: {
            save() {
                console.log(this.importForm)
                this.$eventDispatch("save");
            },
            before() {
                this.importForm.active--
            },
        },
    };
</script>

<style scoped lang="scss">

    .second-title {
        padding: 20px 0;
        position: relative;
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        color: #878787;
        overflow: hidden;

        &:before {
            content: ' ';
            position: absolute;
            left: 60px;
            top: 50%;
            width: 100%;
            border-top: 1px dashed #CCCCCC
        }
    }

    .second-label {
        text-align: right;
        padding-right: 20px;
        color: #595959;
        font-size: 14px;
    }

    .second-content {
        font-size: 12px;
        color: #A6A6A6;
        padding: 20px 0;
    }

    .table-wrapper {
        background: #FCFCFC;
        border: 1px solid #E1E3E6;
        margin: 0 10%;

        .left-wrapper {
            border-right: 1px solid #E6E6E6;
            padding: 0 28px;
            margin: 16px 0 21px 0;

            .left-title {
                font-size: 14px;
                font-weight: bold;
                color: #737373;
                line-height: 40px;
            }

            .left-content {
                padding: 13px 0;
                color: #878787;
                font-size: 14px;
                text-align: center;
                border-bottom: 1px dashed #E6E6E6;

                .required {
                    color: #F56C6C;
                    padding-right: 5px;
                }
            }
        }

        .right-wrapper {
            padding: 0 28px;
            margin: 16px 0 21px 0;

            .right-title {
                font-size: 14px;
                font-weight: bold;
                color: #3C7FFF;
                line-height: 40px;
            }

            .right-select {
                width: 100%;
                margin-top: 14px;
            }
        }
    }

</style>
