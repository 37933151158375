<template>
    <div class="first-step">
        <div class="import-tips">
            <p class="tips1"><img :src="helpUrl" alt="" class="help-img">导入方法</p>
            <p>直接上传填好的资产列表</p>
            <p>或是
                <el-button type="text" @click="download">
                    <img :src="downloadUrl" alt="" class="download-img"> 下载模板
                </el-button>
                并按照模板填充数据，上传填好的文件
            </p>
        </div>
        <div class="excel-wrapper" :class="[importForm.file !== '' ? 'active-excel' : 'inactive-excel']">
            <div class="fileName">{{ importForm.file.name }}</div>
            <i class="el-icon-circle-close cancel-img" @click="delFile"></i>
        </div>
        <div class="upload-wrapper">
            <el-upload
                ref="upload"
                :action="action"
                :headers="headers"
                accept=".xlsx,.xls"
                :on-change="uploadChange"
                :on-success="handleUpload"
                :with-credentials="true"
                :auto-upload="false"
                :show-file-list="false"
                :limit="1"
            >
                <el-button type="primary" plain>上传文件</el-button>
            </el-upload>
            <div>
                <p class="upload-tips">文件后缀名必须为xls或xlsx（即Excel格式)</p>
                <p class="upload-tips">文件大小不得大于10M。</p>
            </div>
        </div>
        <div style="text-align: center">
            <!-- <el-button type="primary" @click="next">下一步</el-button> -->
            <el-button type="primary" @click="save">下一步</el-button>
            <el-button type="info" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
    import { getToken } from '@/libs/auth'
    import { uploadAction } from '@/libs/settings'

    export default {
        props: {
            schools: {
                type: Array,
            },
            importForm: {
                type: Object
            }
        },
        computed: {
            helpUrl() {
                return require('@/assets/images/import/help.png')
            },
            downloadUrl() {
                return require('@/assets/images/import/download.png')
            },
            action() {
                return '/api/operate/common/uploadFile'
            },
            headers() {
                return {
                    'Authorization': getToken()
                }
            }
        },
        methods: {
            delFile() {
                this.importForm.file = ''
                this.$refs.upload.clearFiles()
            },
            download() {
                this.$eventDispatch('download')
            },
            handleUpload(response, file, fileList)	{
                console.log("success",file)
            },
            uploadChange(file) {
                console.log(file, 'file')
                const isImg = ['.xlsx', '.xls'].includes(file.name.substring(file.name.lastIndexOf('.')))
                const isLt10M = file.size / 1024 / 1024 < 10
                const is20 = file.name.length < 30
                if (!isImg) {
                    this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                    this.$refs.upload.clearFiles()
                    return false
                }
                if (!is20) {
                    this.$message.error('上传文件名称不能超过30个字符!');
                    this.$refs.upload.clearFiles()
                    return false
                }
                if (!isLt10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`)
                    this.$refs.upload.clearFiles()
                    return false
                }
                console.log(file,"file")
                this.importForm.file = file
            },
            save() {
                console.log(this.importForm,'1111111111')
                this.$eventDispatch("save");
            },
            cancel() {
                this.$eventDispatch('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .first-step {
        margin: 0 20%;
    }

    .import-tips {
        margin-top: 29px;
        text-align: left;
        height: 65px;
        font-size: 14px;
        color: #595959;
        line-height: 24px;

        .tips1 {
            display: flex;
            align-items: center;
            font-weight: bold;
        }
    }

    .help-img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .download-img {
        width: 11px;
        height: 13px;
        margin-right: 5px;
    }

    .active-excel {
        background-image: url('~@/assets/images/import/excell.png');
    }

    .inactive-excel {
        background-image: url('~@/assets/images/import/excel1.png');
    }

    .excel-wrapper {
        position: relative;
        margin: 40px 0 29px 0;
        width: 100%;
        height: 170px;
        background-color: #f5f7fa;
        background-repeat: no-repeat;
        background-size: 20%;
        background-position: center 20%;

        .fileName {
            position: absolute;
            bottom: 28px;
            left: 50%;
            transform: translate(-50%);
            white-space: nowrap;
            font-size: 18px;
            font-weight: bold;
            color: #595959;
        }

        .cancel-img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            font-size: 20px;
            color: #c1cedb;

            &:hover {
                color: #faafaf;
            }
        }
    }

    .upload-wrapper {
        display: flex;
        margin-bottom: 60px;

        .upload-tips {
            margin-left: 16px;
            font-size: 12px;
            color: #A6A6A6;
            line-height: 22px;
        }
    }

</style>
